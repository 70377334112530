#custom-carousel img, #custom-carousel video{
    border-radius: 20px;
    max-width: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1920px){
    #custom-carousel img, #custom-carousel video{
        width: 700px;
        height: 400px;
    }
}


@media screen and (max-width: 1366px){
    #custom-carousel img, #custom-carousel video{
        width: 550px;
        height: 300px;
    }
}

#custom-carousel .carousel-figure .carousel-figure-title{
    font-weight: 600;
}

#custom-carousel .carousel-arrow{
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgb(255, 255, 255);
    box-shadow: 0px 2px 1px 0px rgb(148 148 148 / 20%);
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;
}

#custom-carousel .carousel-left-arrow{
    left: calc(24% + 1px);
}

#custom-carousel .carousel-right-arrow{
    right: calc(24% + 1px);
}

#custom-carousel .react-multi-carousel-dot.react-multi-carousel-dot--active button{
    border-color: transparent;
    background-color: #161615;
}

#custom-carousel .react-multi-carousel-dot button{
    border-width: 1px;
    background-color: transparent;
}

#custom-carousel .carousel-description{
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 30px;
}

#custom-carousel .react-multi-carousel-item:not(.react-multi-carousel-item--active) .carousel-figure{
    opacity: .5;
    zoom: .88;
}

#logo-image {
    height: 110px;
}