.reason-box {
    box-shadow: 0 20px 60px 0 rgb(62 62 62 / 16%);
}

.como-fazemos-img img{
    max-width: 100%;
    border-radius: 10px;
}

@media (max-width: 767px) {
    #como-fazemos-img img{
        max-width: 100%;
    }
}