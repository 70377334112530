.title-div {
  padding-bottom: 80px !important;
}

.item-1 > img {
  border-radius: 12px;
}

.call-to-action {
  color: #ed1651 !important;
}

