#announcement img {
  height: 220px;
  max-width: 100%;
  margin: 0 auto;
}

.strip {
  background: #ed1651;
  padding: 20px;
}
