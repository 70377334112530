.form-section-cep {
  padding: 20px 30px !important;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: -1px 7px 10px 1px rgb(162 162 162 / 80%);
  position: relative;
  top: -50px;
}

/* .form-cep input.text-input {
  width: 30% !important;
}

.form-cep input.cep-input {
  width: 40% !important;
} */

.form-section-cep input {
  margin-top: 20px;
}

.h-100px {
  height: 100px;
}

.form-cep input {
  outline: 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 20px;
  border-bottom: 1px solid #eee;
}

.form-cep input::placeholder {
  color: #b5b5b5;
}

.form-cep button {
  margin-left: 10px;
}

.form-cep-button{
  margin-left: 10px !important;
  margin-top: 20px;
}

@media (max-width: 991px) {
  .form-cep .submit-form {
    margin-top: 20px;
    text-align: center !important;
  }

  .form-cep .inputs-fields {
    flex-direction: column;
  }

  .form-cep .inputs-fields input {
    width: 100%;
    font-size: 18px;
  }

  .form-cep .inputs-fields input:last-child {
    margin-top: 20px;
  }
}
