#socio-page .intro-subtitle {
  text-align: right !important;
}

#socio-page #intro {
  margin-left: 70px;
  margin-right: 70px;
  height: 550px;
  border-radius: 12px;
}

@media (max-width: 767px) {
  #socio-page #intro {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
  }
}
