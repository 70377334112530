.card-plan{
    border-radius: 1rem;
}

/* .plan-header-speed{
    width: 170px;
    height: 130px;
    padding: 30px;
    border-radius: 0 0 100px 0;
    -moz-border-radius: 1rem 0 100px 0;
    -webkit-border-radius: 1rem 0 100px 0;
} */

.red-plan{
    background-color: #ed1651;
}

.blue-plan{
    background-color: #5c2d91;
}

.gray-plan{
    background-color: #656565;
}

.plan-list{
    list-style: square !important;
}