@import "../../../customCss/animates.css";

.stripe-background {
  background-color: #ed1651;
  padding: 30px;
}

.image-work-together {
  height: 320px;
  width: 100%;
  object-fit: cover;
}

.form-hubspot {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f1f1f1;
  overflow-x: scroll;
  padding: 20px;
  z-index: 100000;
}

.style-modal{
  width: 100%;
}

[data-form]{
  display: none;
}

[data-form].activeModal{
  display: block;
  animation: AnimateUp .5s forwards;
}