#contact-page #banner .contact-banner{
    background-color: #da0035ff;
    border-radius: 5px;
}

#contact-page .faq-item-top span{
    color: #ed1651 !important;
    font-weight: 600;
    font-size: 15px;
}

#contact-page .faq-item{
    padding: 0;
}

#contact-page .faq-item .faq-item-top{
    padding-top: 30px;
    padding-bottom: 30px;
}

#contact-page .faq-item.active-question .faq-item-top{
    border-bottom: 2px solid #ed1651;
}

#contact-page .faq-item .faq-item-content{
    margin-bottom: 40px;
}

#contact-page .faq-item .faq-item-content p{
    color: #858585ff;
    line-height: 25px;
}

#contact-page #first-section a:hover span{
    text-decoration: underline;
}