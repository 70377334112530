.background-image {
  background: url("../../../assets/img/IOT/background.png") no-repeat center;
  background-size: cover;
  border-radius: 12px;
}

.text-iot {
  line-height: 60px;
}

.img-iot {
  position: relative;
  right: 110px;
}
