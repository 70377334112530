footer{
  /*background-color: #f1f1f1ff;*/
  padding-top: 50px !important;
}

.footer-icon {
  margin-right: 0.7em;
  font-size: 1.24em;
}

footer a{
  font-size: 15px;
}

footer ul{
  padding-left: 0;
}

footer .footer-list li:not(:last-child){
  margin-bottom: 10px;
}

footer #footer-newsletter-form input{
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 10px 0 10px 20px;
  box-shadow: -1px 7px 10px 1px rgb(162 162 162 / 20%);
  width: 100%;
  font-size: 14px;
}

footer #footer-newsletter-form input:focus{
  outline: none;
  border: 1px solid #ed1651;
}

footer #footer-newsletter-form button{
  background-color: #ed1651;
    color: #FFF;
    border: 0;
    padding: 15px;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

footer #footer-newsletter-form button:hover{
  opacity: .7;
}