header.header {
  padding: 15px 0;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  z-index: 9999;
  box-shadow: -1px 1px 10px 0px rgb(162 162 162 / 20%);
  background-color: #FFF;
}

header.header span{
  font-size: 15px;
  font-weight: 400;
  color: #c2c2c2;
}


.dropdown-loviz{
  background-color: transparent !important;
  color: black;
  padding: 0 !important;
  border: 0 !important;
}

.dropdown-loviz:hover{
  color: #ed1651;
  border-bottom: 2px solid #ed1651;
  box-shadow: none !important;
}

.dropdown-loviz:active{
  color: #ed1651;
  border-bottom: 2px solid #ed1651 !important;
}

.dropdown-loviz:focus{
  color: #ed1651 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0;
}

.dropdown-loviz::after{
  display: none;
}

.dropdown-loviz:focus-visible{
  border: 0 !important;
}

.show>.dropdown-loviz {
  color: #ed1651 !important;
  box-shadow: none !important;
  /*background-color: #0a58ca;*/
  /*border-color: #0a53be;*/
}

.header-menu ul,
li,
a {
  list-style: none;
  text-decoration: none;
}

.header-menu-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
}

.header-menu-items > li {
  margin: 0 12px;
}

.header-menu-items li a {
  display: block;
  padding: 5px;
  font-size: 16px;
  font-weight: 600;
  color: rgb(51, 51, 51);
}


.header-menu-items li a > span{
  color: rgb(51, 51, 51);
}

.header-menu-items li a span{
  font-weight: 600;
}

.header-menu-items li > a:hover {
  color: #ed1651;
  border-bottom: 2px solid #ed1651;
}

.header-menu-items li > a:hover > div {
  display: block;
}

.header-menu-items li a div{
  display: none;
  position: absolute;
  background-color: #FFF;
  top: calc(100% - 2px);
  border-radius: 5px;
  padding: 10px 20px;
  border-top: 2px solid;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  left: -90px;
  width: calc(100% + 90px);
}

.header-menu-items li a div li:not(:last-child){
  padding-bottom: 10px;
}

.header-menu-items li > a.active {
  color: #ed1651;
  border-bottom: 2px solid #ed1651;
}

.header-menu-items li > a:hover span, .header-menu-items li > a.active span{
  color: #ed1651;
}

.responsive-menu {
  display: none;
}

.header-menu-mobile {
  display: none;
}

@media (max-width: 1199px) {
  header.header .left-column{
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
  }

  header.header img{
    width: 150px;
  }

  .header-menu-items li{
    margin: 0 5px;
  }
}

@media (max-width: 767px){
  .header-menu-items li{
    margin: 10px 5px;
  }

  .header-menu-items li a div{
    position: relative;
    left: 0;
  }

  .menu-container{
    position: fixed;
    bottom: 0;
    right: -100%;
    top: 0;
    z-index: 999;
    height: 100%;
    background-color: #FFF;
    width: 85%;
    flex-direction: column;
    display: flex;
    padding: 30px 0 30px 20px;
  }

  .menu-container.active-mobile-menu{
    -webkit-animation: right-slide 0.2s forwards;
    -webkit-animation-delay: 2s;
    animation: right-slide 0.2s forwards;
    animation-delay: 0;
  }

  .menu-container .header-menu-items{
    padding-left: 0;
    margin-top: 50px;
    flex-direction: column;
    display: block !important;
  }
}


header.header .menu-mobile-top{
  padding-right: 20px;
}