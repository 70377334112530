.button-test {
  padding: 12px;
  background-color: #ed1651;
  border-radius: 100%;
  border: 1px solid #707070ff;
  width: 280px;
  height: 280px;
}

.velocity-test-block {
  background-color: #98213eff;
  max-width: 100px;
  border-radius: 5px;
}

.link-button {
  padding: 5px;
}
