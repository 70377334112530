#tab ul{
    padding-left: 0;
    position: relative;
    margin-bottom: 0;
}

#tab ul li{
    flex: 1 1 0;
    height: auto;
    cursor: pointer;
    color: #b5b5b5;
}

#tab ul li a{
    height: calc(100% + (4px));
    padding: 15px 0;
    display: block;
    position: relative;
}

#tab ul li a::after{
    background: rgb(237, 22, 81, .3);
    bottom: 0;
    content: '';
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 0;
}


#tab ul li.active a::after{
    background: rgb(237, 22, 81);
}

#tab ul li:hover, #tab ul li.active{
    color: #1f1f1f;
}

#tab .tab-content{
    padding-top: 20px;
}

@media (max-width: 767px) {
    #tab ul li a{
        font-size: 15px !important;
    }
}