.form-cta {
  position: fixed;
  background-color: rgba(0, 0, 0, 42%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;
} 

.title-cta {
  color: #1a73e9ff;
}

.form-cta > div {
  max-width: 460px;
  border-radius: 15px;
  padding: 20px !important;
  animation: goDown .5s forwards;
}

.form-name input{
  width: 50%;
  border: 0;
  outline: 0;
  margin-left: 5px;
  padding: 8px 6px;
  border-bottom: 1px solid #c2c2c2;
}

.form-email input {
  width: 100%;
  border: 0;
  outline: 0;
  margin:0 5px;
  padding: 8px 6px;
  border-bottom: 1px solid #c2c2c2;
}

.form-button a {
  display: block;
  width: 50%;
  background-color: #f2f2f2;
  padding: 8px 6px;
}

.form-button a:first-child {
  background-color: #1a73e9ff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.form-button a:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.form-cpf input{
  width: 25%;
  border: 0;
  outline: 0;
  border-bottom: 1px solid #c2c2c2;
  margin: 0 5px;
}

.form-button-person a{
  display: block;
  width: 50%;
  background-color: #f2f2f2;
  padding: 8px 6px;
  border: 1px solid #c2c2c2;
}

.form-button-person a:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.form-button-person a:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.button-cta {
  margin-top: 20px;
  width: 210px;
  border-radius: 50px;
  padding: 15px 5px;
  outline: 0;
  border: 0;
  background-color: #FF6702;
}

@keyframes goDown {
  from {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
}

.close-modal{
  cursor: pointer;
}