#company-page #banner .company-banner{
    background-color: #da0035ff;
    border-radius: 5px;
}

#company-page .company-form .form-section-cep{
    box-shadow: none;
    padding: 0 !important;
    background-color: transparent;
    top: inherit;
}


#company-page .company-form .form-section-cep form.form-cep input{
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    border-radius: 5px;
    font-size: 17px;
    padding: 10px 20px;
}

#company-page .company-form .form-section-cep form.form-cep input:last-child{
    margin-left: 20px;
}

#company-page .company-form .form-section-cep form.form-cep button{
    margin: 0;
    padding: 15px 20px;
    width: 100%;
}

#company-page #stay-away{
    background-position: top !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
}

#company-page #price-section{
    background: linear-gradient(to right, #da0035bf 5%, #da0035ff 5.1% 95%, #da0035bf 95.1%);
}

#company-page #company-plans .company-plan-item{
    background-color: #FFF;
    border-radius: 5px;
    border-top: 5px solid #ed1651;
    box-shadow: -1px 7px 10px 1px rgb(162 162 162 / 20%);
}

#company-page #company-plans .company-plan-item .plan-top, #company-page #company-plans .company-plan-item .plan-content{
    padding: 30px 20px;
}

#company-page #custom-carousel img {
    width: 200px;
    height: 200px;
}

.plan-top{
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#company-page #company-plans .company-plan-item .plan-content ul li:not(:first-child){
    margin-top: 30px;
}

#company-page #company-plans .company-plan-item .plan-content ul{
    padding-left: 10px;
}

#company-page #company-plans .company-plan-item .plan-content ul li{
    list-style: disc;
}

@media (max-width: 991px) {
    #company-page #prepare-your-internet .prepare-your-internet-col{
        padding-right: calc(var(--bs-gutter-x) * .5) !important;    
        padding-left: calc(var(--bs-gutter-x) * .5) !important;    
    }

    #company-page .company-form .form-section-cep form.form-cep input:last-child{
        margin-left: 0;
    }

}

@media (min-width: 768px) and (max-width: 1199px){
    #company-page #company-plans .plans-row{
        flex-wrap: nowrap;
    }
}

@media (min-width: 1200px) {
    #company-page #company-plans .plan-col{
        padding: 0 40px;
    }
}