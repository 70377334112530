#super-wifi #first-section{
    background: linear-gradient(to left, rgb(255,255,255) 15%, transparent), url("../../../assets/img/super-wifi-first-section.webp") center no-repeat;
    background-size: cover !important;
    padding: 90px 0;
}

@media (max-width: 1023px) {
    #super-wifi #first-section h2{
        font-size: 40px;
    }
}