.iot-text {
  line-height: 21px;
}

#information-iot img {
  height: 420px;
  width: 100%;
}

#information-iot p {
  line-height: 30px;
}
