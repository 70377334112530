.compliance .content h1,
.compliance .content h2,
.compliance .content h3,
.compliance .content h4,
.compliance .content h5,
.compliance .content h6 {
    color: #DA0035;
    text-transform: uppercase;
    font-weight: 600;
}

.compliance .content p {
    margin-bottom: 1.2em;
    word-break: break-word;
}

.compliance .content li {
    list-style: auto;
    margin-bottom: 1.2em
}

#pre-compliance blockquote{
    border-left: 6px solid #DA0035;
}

#pre-compliance blockquote p{
    margin-bottom: 0px;
}

