#custom-col-md-10 >  div{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 24px;
}

#custom-col-md-10{
    padding: 16px;
}

.home-wifi__image {
    margin-bottom: 48px;
    position: relative;
}
@media (min-width: 1024px) {
    .home-wifi__image {
        margin-bottom: 60px;
    }
}
#wifi-section img {
    border-radius: 8px;
}

#wifi-section .principal-image {
    position: relative;
    width: 44%;
    z-index: 10;
}
@media (min-width: 600px) {
    #wifi-section .principal-image {
        width: 34%;
    }
}
#wifi-section .secondary-image {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -48%);
    width: 38%;
}
@media (min-width: 600px) {
    #wifi-section .secondary-image {
        width: 28%;
    }
}



/* @media (max-width: 1199px){
    #wifi-section img{
        height: 280px !important;
    }

    #wifi-section img:nth-child(2){
        height: 320px;
    }
} */