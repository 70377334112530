#intro{
    height: 650px;
    background-size: cover !important;
}

#intro h1{
    font-size: 45px;
    font-weight: 400;
    letter-spacing: 2px;
}

#intro .intro-subtitle h2{
    font-weight: 900;
    font-size: 25px;
}

#intro .intro-subtitle span{
    font-weight: 600;
}


@media (max-width: 991px) {
    #intro h1{
        font-size: 35px;
    }
}