#introduction-second {
  padding: 100px 0;
}

.car-running {
  object-fit: cover;
  display: block;
  max-width: 100%;
}

.house-animation{
  object-fit: cover;
  display: block;
  max-width: 80%;
}

#fast-before::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 24px;
  background-color: #b5b5b5;
  margin-right: 25px;
}

.quotes {
  border-left: 1px solid #b5b5b5;
  padding: 0 25px;
}
