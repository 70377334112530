#education-page .intro-subtitle{
    text-align: right !important;
}

#education-page #intro{
    margin-left: 70px;
    margin-right: 70px;
    height: 550px;
}

@media (max-width: 767px) {
    #education-page #intro{
        margin-left: 0;
        margin-right: 0;
    }
}