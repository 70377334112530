.custom-modal{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,.6);
    z-index: 99999;
}

.custom-modal .modal-container{
    background-color: #FFF;
    border-radius: 5px;
    padding: 30px 15px;
}

@media (max-width: 991px) {
    .custom-modal .modal-container h3{
        font-size: 20px !important;
    }
}