#contact-form input, #contact-form textarea{
    padding: 10px 20px;
    border-radius: 8px;
    outline: 0;
    border: 1px solid #d3dbee;
    background-color: transparent;
    width: 100%;
    resize: none;
    font-size: 14px;
}

.contact-submit{
    padding: 10px 20px;
    border-radius: 8px;
    outline: 0;
    border: 0;
    cursor: pointer;
    width: 100%;
    background-color: #1a73e9ff;
    font-weight: 600;
    font-size: 20px;
    /*text-transform: uppercase;*/
    letter-spacing: 0.2px;
    color: #FFF;
}

.contact-submit:visited {
    color: #FFF;
}

.contact-submit:hover {
    color: #000000;
}

#contact-form p{
    line-height: 17px;
    font-size: 11px;
}