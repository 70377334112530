.faq-item{
    padding: 30px 0;
    border-bottom: 1px solid #ddd;
}

.faq-item .faq-item-content{
    display: none;
}

.faq-item .faq-item-top{
    cursor: pointer;
}

.faq-item.active-question .faq-item-content{
    display: block;
}

.faq-item.active-question .faq-item-top svg{
    transform: rotate(180deg);
}