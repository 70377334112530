.vertical-tab .tab-items .tab-item{
    padding-top: 30px;
    padding-bottom: 30px;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #e4e3e3;
    border-right: 1px solid #e4e3e3;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.vertical-tab .tab-items .tab-item::after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    height: 100%;
    background-color: rgba(237, 22, 81, .3);
}

.vertical-tab .tab-items .tab-item.active::after{
    background-color: rgba(237, 22, 81);
}

.vertical-tab .tab-items .tab-item.active{
    border-left-color: #ed1651;
    background-color: transparent;
    border-right-color: transparent;
}

.vertical-tab .tab-content{
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e4e3e3
}

@media (min-width: 1140px) {
    .vertical-tab .tab-content-container{
        padding-left: 50px;
    }
}

@media(max-width: 1139px){
    .vertical-tab select[name="tabs"]{
        background-color: transparent;
        width: 100%;
        border: 0;
        border-bottom: 2px solid #ed1651;
        height: 50px;
        font-size: 18px;
    }

    .vertical-tab select[name="tabs"]:focus-visible{
        outline: none !important;
    }
}