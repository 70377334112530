#to-condominium .intro-subtitle{
    text-align: right !important;
}

#to-condominium #intro{
    /* margin-left: 70px;
    margin-right: 70px; */
    height: 550px;
}

@media (max-width: 767px) {
    #to-condominium #intro{
        margin-left: 0;
        margin-right: 0;
    }
}