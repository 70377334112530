.fonts-title {
  font-size: 45px;
  font-weight: 400;
  letter-spacing: 2px;
  color: #1f1f1f;
}

.fonts-subtitle {
  margin-left: 5px;
  font-size: 15px;
  font-weight: 300;
  font-style: italic;
  color: #c2c2c2;
}

.fonts-subtitle-2 {
  margin-left: 5px;
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
  color: #c2c2c2;
}

.fonts-subtitle-3 {
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
  color: #1f1f1f;
}

.fonts-subtitle-4 {
  font-size: 26px;
  font-weight: 400;
  color: #1f1f1f;
}

.fonts-subtitle-5 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.fonts-subtitle-6 {
  font-size: 15px;
  font-weight: 400;
  color: #1f1f1f;
}

.fonts-subtitle-menu {
  display: block;
  padding: 5px;
  font-size: 16px;
  font-weight: 600;
  color: rgb(51, 51, 51);
  transition: 0.5s ease-out;
}

.fonts-menu-active {
  color: #ed1651;
}

.fonts-input {
  font-size: 24px;
  font-weight: 200;
  color: rgba(0, 0, 0, 85%);
}

.fonts-button {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
}

#custom-fonts-subtitle-6 {
  margin-top: 24px;
}
