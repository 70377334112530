.plan-header-speed{
    width: 230px;
    height: 130px;
    padding: 30px;
    border-radius: 0 0 100px 0;
    -moz-border-radius: 1rem 0 100px 0;
    -webkit-border-radius: 1rem 0 100px 0;
}

.red-plan{
    background-color: red;
}

.blue-plan{
    background-color: blue;
}

.rounded-card{
    border-radius: 1rem;
}

.enterprise_value{
    font-size: 1.6rem;
}

.main-plan-list{
    list-style: square !important;
    font-weight: bold;
}

.sub-plan-list{
    list-style: square !important;
}