#superwifi-section #router-section img{
    height: 38vh;
    border-radius: 10px;
    width: 100%;
}

#superwifi-section #wifi-to-all img{
    height: 55vh;
    border-radius: 10px;
}

