#solution-page #first-solution-section .form-section-cep{
  box-shadow: 0px 0 10px 1px rgb(162 162 162 / 20%);
  padding: 40px;
  top: 0;
}

#solution-page #first-solution-section .form-section-cep span{
  font-size: 38px;
  color: #212529;
  font-style: normal;
  letter-spacing: -1px;
}

#solution-page #first-phone-section .form-section-cep .submit-form > button{
  width: 100%;
  margin-left: 0;
}

#solution-page #first-solution-section .first-section-background {
  background-position: top !important;
  background-size: cover !important;
}

.first-section-background::after{
  content: "";
}

@media(max-width: 767px){
  #solution-page #first-solution-section .form-section-cep span{
      font-size: 23px !important;
  }
  
  #solution-page #first-solution-section .form-section-cep{
      padding: 25px !important;
  }

  #solution-page #benefits .benefits-items .order-2{
      order: inherit !important;
  }

  #solution-page #benefits .benefits-items img{
      margin-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1199px){
  #phone-page #first-phone-section .form-section-cep span{
      font-size: 25px !important;
  }
}