#home-page .form-cep input.email-input {
  width: 100%;
}

#home-page .form-cep input.cep-input {
  width: 100%;
}

#home-page .form-cep input:nth-child(3) {
  width: 50%;
}

#home-page h2{
  font-size: 50px !important;
}

#home-page .mega-style {
  border: 2px solid red;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
}

#wifi-section{
  background-color: #f1f1f1ff;
}

.banner__content {
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  width: 535px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.offer-left{
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.vertical-hr{
  color: red;
  box-sizing: inherit;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  border-left: 2px solid red;
  margin: 0;
  height: 6.151rem;
  border-bottom: 0;
}

.offer-price{
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  color: red;
  align-self: center;
}

.price-wrapper{
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  justify-content: center;
}

.first-col{
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  flex-direction: column;
  line-height: 28px;
}

.por{
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  text-align: left;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 30px;
}

.rs{
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  font-weight: 500;
  font-size: 30px;
}

.second-col{
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

.price{
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  font-weight: 700!important;
  margin-top: -0.188rem;
  font-size: 4rem;
  line-height: 78px;
}

.third-col{
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-start;
}

.forth-col{
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  align-self: center;
}

.cents{
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  font-weight: 700!important;
  margin-bottom: 0.125rem;
  font-size: 2.938rem;
}

.mes{
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  text-transform: uppercase;
  margin-left: 0.6rem;
  font-weight: 700!important;
  font-size: 13px;
}

.megas{
  color: red;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  text-transform: uppercase;
  margin-right: 0.6rem;
  font-weight: 700!important;
  font-size: 20px;
  text-align: end;
}

.banner__offer{
  position: relative;
  width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  border: 2px solid red;
  border-radius: 20px;
}

.offer-button-content {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.offer-button-content a {
  background-color: red;
  color: #fff !important;
  border-radius: 30px;
  margin: auto;
  width: max-content;
  transition: all 0.3s ease-in-out;
  font-size: 0.8rem;
  padding: 10px 22px;
}
